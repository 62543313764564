import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styles from "../css/header.module.css"
import links from "../constants/links"

const Header = ({ siteTitle }) => (
  <header className={styles.wrapper}>
    <div>
      <nav>
        <Link className={styles.logo} to="/">steven yu</Link>
        <ul className={styles.test}>
          {links.map((item, index) => {
            return (
              <li className={styles.header} key={index}><Link activeClassName="active" to={item.path}> {item.text} </Link></li>
            )
          })}
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
