/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import Layout from "./src/components/layout";
import "./public/static/fonts/font.css"

const transitionDelay = 600;

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    if (!location.hash) {
        if (location.action === 'PUSH') {
            window.setTimeout(() => {
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                })
            }, 550)
        } else {
            const savedPosition = getSavedScrollPosition(location)
            window.setTimeout(
                () => window.scrollTo(...(savedPosition || [0, 0])),
                550
            )
        }
    }

    return false
}

