export default [
    // {
    //     path:"/",
    //     text:"home",
    // },
    // {
    //     path: "/projects",
    //     text: "projects",
    // },
    {
        path:"/about",
        text:"about",
    },
  
]