module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149864630-2","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"variationId":"YOUR_GOOGLE_OPTIMIZE_VARIATION_ID","defer":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"steven-yu-portofolio","short_name":"steven yu","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/about/","/allecon/","/aeip/","/casseti/","/forila/","/isip/","/pixel-human/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"black","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
